<template>
	<div class="timer">
		<div v-if="size == 'sml'">
			<div class="font-mono text-gray-100 flex text-center op">
				<div
					class="w-t mx-1 p-2 bg-gray-800 rounded-lg flex flex-col gap-4 py-4"
				>
					<div class="leading-none text-4xl" x-text="hours">
						{{ timerObj.hour }}
					</div>
					<div class="uppercase text-lg leading-none">Hours</div>
				</div>
				<div
					class="w-t mx-1 p-2 bg-gray-800 rounded-lg flex flex-col gap-4 py-4"
				>
					<div class="leading-none text-4xl" x-text="hours">
						{{ timerObj.min }}
					</div>
					<div class="uppercase text-lg leading-none">Minutes</div>
				</div>
				<div
					class="w-t mx-1 p-2 bg-gray-800 rounded-lg flex flex-col gap-4 py-4"
				>
					<div class="leading-none text-4xl" x-text="hours">
						{{ timerObj.sec }}
					</div>
					<div class="uppercase text-lg leading-none">Seconds</div>
				</div>
			</div>
		</div>
		<div
			v-else
			class="min-w-screen bg-gray-800 bg-opacity-75 flex items-center justify-center px-5 py-10"
		>
			<div class="text-red-100">
				<div
					v-if="timerEnd"
					class="sm:text-6xl text-3xl text-center flex w-full items-center justify-center select-none"
				>
					<div
						class="sm:w-24 xl:w-60 w-16 mx-1 p-2 bg-white text-red-900 rounded-lg"
					>
						<div
							class="font-mono leading-none xl:text-9xl"
							x-text="hours"
						>
							{{ timerObj.hour }}
						</div>
						<div
							class="font-mono uppercase xl:text-xl md:text-sm text-xs leading-none"
						>
							Hours
						</div>
					</div>
					<div
						class="sm:w-24 xl:w-60 w-16 mx-1 p-2 bg-white text-red-900 rounded-lg"
					>
						<div
							class="font-mono leading-none xl:text-9xl"
							x-text="minutes"
						>
							{{ timerObj.min }}
						</div>
						<div
							class="font-mono uppercase xl:text-xl md:text-sm text-xs leading-none"
						>
							Minutes
						</div>
					</div>
					<div
						class="sm:w-24 xl:w-60 w-16 mx-1 p-2 bg-white text-red-900 rounded-lg"
					>
						<div
							class="font-mono leading-none xl:text-9xl"
							x-text="seconds"
						>
							{{ timerObj.sec }}
						</div>
						<div
							class="font-mono uppercase xl:text-xl md:text-sm text-xs leading-none"
						>
							Seconds
						</div>
					</div>
				</div>
				<div
					v-else
					class="animate-pulse flex sm:text-6xl text-3xl text-center flex w-full items-center justify-center select-none"
				>
					<div
						class="sm:w-24 w-16 mx-1 p-2 bg-gray-500 text-red-900 rounded-lg"
					>
						<div
							class="font-mono leading-none text-gray-600"
							x-text="hours"
						>
							{{ timerObj.hour }}
						</div>
						<div
							class="font-mono uppercase sm:text-sm text-xs leading-none text-gray-600"
						>
							Hours
						</div>
					</div>
					<div
						class="sm:w-24 w-16 mx-1 p-2 bg-gray-500 text-red-900 rounded-lg"
					>
						<div
							class="font-mono leading-none text-gray-600"
							x-text="minutes"
						>
							{{ timerObj.min }}
						</div>
						<div
							class="font-mono uppercase sm:text-sm text-xs leading-none text-gray-600"
						>
							Minutes
						</div>
					</div>
					<div
						class="sm:w-24 w-16 mx-1 p-2 bg-gray-500 text-red-900 rounded-lg"
					>
						<div
							class="font-mono leading-none text-gray-600"
							x-text="seconds"
						>
							{{ timerObj.sec }}
						</div>
						<div
							class="font-mono uppercase sm:text-sm text-xs leading-none text-gray-600"
						>
							Seconds
						</div>
					</div>
					<div class="max-w-md w-full mx-auto"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "HelloWorld",
		props: ["timerTitle", "size"],
		data: function () {
			return {
				timerStart: false,
				timerEnd: null,
				timerObj: {
					day: "00",
					hour: "00",
					min: "00",
					sec: "00",
				},
				now: new Date().getTime(),
				timerInterval: "",
				distance: 0,
			};
		},
		mounted() {
			//this.timerEnd = new Date('January 5, 2022 00:00:00').getTime();
			this.getTimeEnd();
		},
		beforeUnmount() {
			this.stop();
		},
		watch: {},
		methods: {
			getTimeEnd() {
				const self = this;
				this.$http
					.get(process.env.VUE_APP_API_ROOT + "/timer")
					.then(function (response) {
						if (response.status == 200) {
							const year = response.data.timer.slice(0, 4);
							const mon = response.data.timer.slice(5, 7);
							const day = response.data.timer.slice(8, 10);
							const time = response.data.timer
								.slice(11)
								.split(":");
							const hour = time[0];
							const min = time[1];
							self.timerEnd = new Date(
								year,
								mon - 1,
								day,
								hour,
								min
							).getTime();
							self.start();
						}
					})
					.catch(function () {
						//
					});
			},
			start() {
				this.timerInterval = this.countdown();
			},
			stop() {
				clearInterval(this.timerInterval);
			},
			padNum(num) {
				let zero = "";
				for (let i = 0; i < 2; i++) {
					zero += "0";
				}
				return (zero + num).slice(-2);
			},
			countdown() {
				const self = this;
				return setInterval(() => {
					self.now = new Date().getTime();
					self.distance = self.now - self.timerEnd;
					self.timerObj.day = Math.floor(
						self.distance / (1000 * 60 * 60 * 24)
					);
					if (self.timerObj.day > 0) {
						self.timerObj.hour = self.padNum(
							Math.floor(
								(self.distance % (1000 * 60 * 60 * 24)) /
									(1000 * 60 * 60)
							) +
								self.timerObj.day * 24
						);
					} else {
						self.timerObj.hour = self.padNum(
							Math.floor(
								(self.distance % (1000 * 60 * 60 * 24)) /
									(1000 * 60 * 60)
							)
						);
					}
					self.timerObj.min = self.padNum(
						Math.floor(
							(self.distance % (1000 * 60 * 60)) / (1000 * 60)
						)
					);
					self.timerObj.sec = self.padNum(
						Math.floor((self.distance % (1000 * 60)) / 1000)
					);

					if (self.distance < 0) {
						self.timerObj.day = "00";
						self.timerObj.hour = "00";
						self.timerObj.min = "00";
						self.timerObj.sec = "00";
					} else if (self.distance > 95 * 1000 * 60 * 60) {
						self.timerObj.day = "00";
						self.timerObj.hour = "95";
						self.timerObj.min = "00";
						self.timerObj.sec = "00";
					}
					if (!self.timerEnd) {
						self.timerObj.day = "00";
						self.timerObj.hour = "00";
						self.timerObj.min = "00";
						self.timerObj.sec = "00";
					}
				}, 100);
			},
		},
	};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	.w-t {
		width: 100px;
	}
</style>
