<template>
    <div class="info">
        <div class="right absolute right-0 top-0">
            <div class="fixed right-center">
                <div class="font-spantaran text-center rounded-lg p-2 mx-1 min-w-screen mb-1">
                    <p class="text-xl rfc">RUN FOR CHARITY</p>
                    <p class="text-3xl">#DHUVAAHITHUN</p>
                </div>
                <timer quick="true" size="sml" />
                <totalCanc :totalDistance="totalDistance" />
                <div class="flex justify-between items-end rounded-lg p-2 mx-2 mt-2">
                    <div><img src="/img/bg/adklogo.png" alt="" class="logo adklogo w-20"></div>
                    <div><img src="/img/bg/tiny-logo.png" alt="" class="logo tinylogo  w-20"></div>
                    <p class="text-xl font-spantaran a35">#ADK35</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import timer from "../components/Home/timer.vue";
import totalCanc from "../components/Home/totalCancv.vue";

export default {
    name: 'Home',
    data(){
        return {
            isLoading: true,
            totalDistance: "loading",
            kminterval: null,
        }
    },
    mounted(){

        
        const self = this;
        const refresh = this.$route.query.refresh?this.$route.query.refresh:5000;
        self.getKm()
        this.kminterval = setInterval(function(){
        self.getKm();
        },refresh)
    },
    beforeUnmount(){
        clearInterval(this.kminterval);
    },
    methods:{
        getKm: function(){
        const self = this;
        this.$http.get(process.env.VUE_APP_API_ROOT+'/total').then(function(responce){
            if(responce.status == 200){
            self.setKm(responce.data.total)
            }
        })
        },
        setKm($km){
        this.totalDistance = $km;
        }
    },
    components: {
        timer,
        totalCanc
    }
}//today = new Date(timeElapsed);
</script>

<style lang="scss" scoped>
.info{
    position: absolute;
    top:0;
    right:0;
    width:calc(100% - 30px);
    height:calc(100% - 30px);
    margin-right: 30px;
    margin-top: 30px;
    .right{
        width: 300px;
        margin-right: 90px;
    }
    .right-center{
        top:50%;
        transform: translateY(-50%);
    }
    .a35{
        line-height: 0.75rem;
    }
}
</style>
<style>
.mendrw{
    display: none;
}
.nobg{
    background: #00b140 !important;
}
.op{
    opacity: 0.85;
}
.bg-gray-800{
    background:#000 !important;
}
</style>