<template>
	<div
		class="rounded-lg p-2 mx-1 min-w-screen bg-gray-800 op flex justify-between px-5 py-5 mt-10 gap-y-5 main"
	>
		<div class="left text-xl flex flex-col">
			<span class="select-none">Km</span
			><span>{{
				new Intl.NumberFormat("en-US").format(
					Number(totalDistance).toFixed(2)
				)
			}}</span>
		</div>
	</div>
</template>

<script>
	export default {
		props: ["totalDistance"],
		setup() {},
	};
</script>

<style lang="scss" scoped>
	.main {
		margin-top: 5px;
	}
	.left {
		border-right: 1px solid #fff;
	}
	.left,
	.right {
		width: 100%;
		text-align: center;
	}
	.sep {
		&::after {
			content: "";
			position: absolute;
			width: 1px;
			height: 100%;
			top: 0;
			left: 0;
			background: #fff;
		}
	}
</style>
